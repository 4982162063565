import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const Picture = ({ xs, md, lg, alt, className }) => (
  <S.Picture className={className}>
    <source media="(max-width: 799px)" srcSet={xs} />
    <source media="(min-width: 800px) and (max-width: 1499px)" srcSet={md} />
    <source media="(min-width: 1500px)" srcSet={lg} />
    <img src={md} alt={alt} className={className} />
  </S.Picture>
)

Picture.defaultProps = {
  className: '',
}

Picture.propTypes = {
  xs: PropTypes.string.isRequired,
  md: PropTypes.string.isRequired,
  lg: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Picture
