import styled from 'styled-components'

export const Picture = styled.picture`
  position: relative;
  width: 100%;

  img {
    object-fit: cover;
    display: block;
    margin: 0 auto;

    @media (max-width: 1700px) {
      width: 100%;
    }
  }

  &.sustainability {
    z-index:100;
    
    img {
      max-width:100%;
    }
  }
`
