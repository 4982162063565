import styled from 'styled-components'

export const BannerTopo = styled.section`
  background-color: #8fdee2;
  width: 100%;
`

export const AboutWrapper = styled.section`
  display: flex;
  width: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  @media (min-width: 1800px) {
    margin: 0 auto;
    max-width: 1700px;
  }

  .item--image {
    width: 100%;

    @media (min-width: 1700px) {
      width: 60%;
    }
  }

  .item--text {
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    padding: 0 40px;
    position: relative;
    width: 100%;

    @media (max-width: 660px) {
      padding: 40px 20px;
      text-align: center;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      padding: 20px;
    }
  }

  h3 {
    font-size: 30px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;

    @media (max-width: 660px) {
      font-size: 25px;
      line-height: 25px;
    }

    /* @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 30px;
      line-height: 30px;
    } */

    span {
      color: #8fdee2;
      font-weight: 700;
      line-height: 60px;

      @media (max-width: 660px) {
        line-height: 40px;
      }
    }
  }

  p {
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    margin-top: 40px;

    @media (max-width: 660px) {
      font-size: 20px;
      line-height: 20px;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 22px;
      line-height: 25px;
    }
  }
`

export const FeaturesWrapper = styled.section`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 100px 0;

  @media (max-width: 767px) {
    padding: 100px 0 30px 0;
  }
`

export const FeatureBox = styled.section`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};

  @media (max-width: 767px) {
    flex-direction: column;
  }

  h2 {
    color: var(--primary-color);
    font-size: 34px;
    font-weight: 400;
    line-height: 35px;

    @media (max-width: 767px) {
      font-size: 28px;
      line-height: 30px;
      margin: 20px 0;
      text-align: center;
    }
  }

  img {
    width: ${({ reverse }) => (reverse ? '270px' : '400px')};

    @media (max-width: 767px) {
      display: block;
      margin: 0 auto;

      &.thumb-cabedal {
        margin-left: 60px;
        width: 190px;
      }

      &.thumb-sola {
        width: 135px;
      }
    }
  }
`

export const FeaturesList = styled.div`
  margin: 30px 0;
  width: 550px;

  @media (max-width: 767px) {
    width: 100%;
  }

  .feature {
    align-items: center;
    display: flex;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      flex-direction: column;
      text-align: center;
    }
  }

  img {
    margin-right: 30px;
    width: 150px;

    @media (max-width: 767px) {
      display: block;
      margin: 0 auto 20px auto;
    }
  }

  p {
    color: var(--primary-color);
    font-size: 19px;
    font-style: italic;
    line-height: 22px;
    width: 270px;
  }
`

export const ModelosWrapper = styled.section`
  background-color: #dcfeff;
  padding: 80px 0 30px 0;
  width: 100%;

  @media (max-width: 767px) {
    padding: 30px 15px;
  }
`

export const ModelosTituloWrapper = styled.h1`
  color: var(--primary-color);
  font-size: 50px;
  font-weight: 400;
  margin-bottom: 50px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 32px;
  }
`

export const ModelosContentTecnologia = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  width: 100%;

  @media (min-width: 992px) {
    flex-flow: row;
  }

  @media (min-width: 1024px) {
    max-width: 1000px;
  }
`

export const ModelosItemTecnologia = styled.div`
  position: relative;

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 0 50px;
    text-align: center;
  }

  h2 {
    color: var(--primary-color);
    font-size: 34px;
    font-family: 'ASICSFont3.0-BoldItalic';
    margin: 15px 0;
    text-align: center;
    text-transform: uppercase;
  }

  img {
    width: 370px;
    margin: 0 auto;
    display: block;

    @media (max-width: 767px) {
      width: 100%;
    }
  }
`

export const OndeComprarWrapper = styled.section`
  background-color: #dcfeff;
  padding: 0 0 80px 0;
  width: 100%;

  @media (max-width: 767px) {
    padding: 0 0 30px 0;
  }

  .container {
    margin: 0 auto;
    max-width: 1070px;
    position: relative;
    width: 100%;

    @media (max-width: 767px) {
      text-align: center;
    }
  }

  h2,
  p {
    color: var(--primary-color);
  }

  h2 {
    font-family: 'ASICSFont3.0-BoldItalic';
    font-size: 30px;
    font-style: italic;
    font-weight: bold;

    @media (max-width: 767px) {
      font-size: 26px;
    }
  }

  p {
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 24px;
    width: 530px;

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 20px;
      padding: 0 55px;
      width: 100%;
    }
  }
`

export const ButtonComprarWrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;

  @media (max-width: 767px) {
    position: initial;
  }
`

export const ButtonComprar = styled.a`
  background-color: var(--primary-color);
  border-radius: 99999px;
  color: #fff;
  display: block;
  font-family: 'ASICSFont3.0-BoldItalic';
  font-size: 16px;
  margin: 10px 0;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease;
  width: 265px;

  &:hover {
    background-color: #fff;
    color: var(--primary-color);
  }
`

export const WrapperNewsletter = styled.section`
  padding: 50px 0;
  width: 100%;

  @media (max-width: 991px) {
    padding: 30px 30px 0 30px;
  }
`

export const ContentNewsletter = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  position: relative;
  width: 100%;

  h2 {
    color: var(--primary-color);
    font-family: 'ASICSFont3.0-BoldItalic';
    font-size: 28px;
    margin-bottom: 5px;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 26px;
      line-height: 26px;
    }
  }
`

export const FormNewsletter = styled.div`
  margin: 30px auto;
  width: 60%;

  @media (max-width: 991px) {
    width: 100%;
  }
`
export const InstagramWrapper = styled.section`
  background-color: #8fdee2;
  height: 670px;
  position: relative;
  width: 100%;
  z-index: 1;

  @media (max-width: 767px) {
    height: auto;
    padding: 60px 0;
  }

  &::before {
    content: '';
    background-color: #fff;
    width: 50%;
    height: 10px;
    display: block;
    position: absolute;
    right: 0;
    top: 60px;

    @media (max-width: 767px) {
      top: 20px;
      width: 80%;
    }
  }

  &::after {
    content: '';
    background-color: #fff;
    width: 40%;
    height: 10px;
    display: block;
    position: absolute;
    left: 60%;
    bottom: 60px;
    z-index: -1;
    transform: translateX(-50%);

    @media (max-width: 767px) {
      bottom: 20px;
      left: 0;
      transform: none;
    }
  }

  .MuiContainer-root {
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  h2 {
    color: var(--primary-color);
    font-size: 52px;
    font-style: italic;
    letter-spacing: 1px;
    line-height: 24px;

    @media (max-width: 767px) {
      font-size: 30px;
    }
  }

  p {
    color: #fff;
    font-size: 52px;
    font-style: italic;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 30px;
    }
  }

  .post-instagram {
    filter: drop-shadow(10px 0 46px rgba(0, 0, 0, 0.48));
    margin-top: 30px;

    @media (min-width: 900px) {
      margin-top: 0;
      position: absolute;
      right: 150px;
    }
  }
`
