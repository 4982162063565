import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const CardTecnologia = ({
  title,
  tecnologias,
  bgColor = '#001E62',
  color = '#FFF',
  classname = '',
}) => {
  return (
    <S.CardTecnologia className={classname}>
      <S.CardTecnologiaHead bgColor={bgColor} color={color}>
        {title}:
      </S.CardTecnologiaHead>
      <S.CardTecnologiaBody>
        <ul>
          {tecnologias.map((tec, index) => (
            <S.CardItemList
              bgColor={bgColor}
              color={color}
              key={`${tec}-${index}`}
            >
              {tec}
            </S.CardItemList>
          ))}
        </ul>
      </S.CardTecnologiaBody>
    </S.CardTecnologia>
  )
}

CardTecnologia.propTypes = {
  title: PropTypes.string.isRequired,
  tecnologias: PropTypes.array.isRequired,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  classname: PropTypes.string,
}

export default CardTecnologia
