import styled from 'styled-components'

export const CardTecnologia = styled.article`
  margin: 0 0 30px 0;

  @media (min-width: 992px) {
    width: 450px;
  }

  &.card-gelnimbus23 {
    align-items: center;
    border: 1px solid #00b388;
    border-radius: 20px;
    display: flex;
    height: 250px;
    overflow: hidden;

    & > header {
      display: none;
    }
  }
`

export const CardTecnologiaHead = styled.header`
  background-color: ${(props) => props.bgColor};
  border-radius: 99999px;
  color: ${(props) => props.color};
  font-size: 18px;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 2px;
  padding: 7px 14px;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

export const CardTecnologiaBody = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  min-height: 140px;
  padding: 35px 15px;
  width: 100%;
`

export const CardItemList = styled.li`
  color: #9697a9;
  font-size: 16px;
  list-style-type: none;
  padding-left: 20px;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &::before {
    content: '';
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    display: block;
    position: absolute;
    top: 5px;
    left: 0;
    background-color: ${(props) => props.bgColor};
    width: 12px;
    height: 12px;
  }
`
