import React from 'react'
import PropTypes from 'prop-types'

export default function EmbedInstagram({ post }) {
  return <div dangerouslySetInnerHTML={{ __html: post }} />
}

EmbedInstagram.propTypes = {
  post: PropTypes.string.isRequired,
}
